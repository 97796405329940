import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axiosInstance from "./utils/requset";
import ElementPlus from 'element-plus';
import echarts from "./utils/echarts";
import "@/css/index.scss";
import 'element-plus/dist/index.css';
import api from './api';

// Vue.use(scroll)

const app = createApp(App);

app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$api = api;
app.config.globalProperties.$axios = axiosInstance;
app.use(ElementPlus);
app.use(router).mount("#app");
