import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f3d9b74"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-page"
};
import NavHeader from "@/components/NavHeader";
// import { onMounted } from "vue";

// onMounted(() => {
//   setCityId();
// });
// const setCityId = () => {
//   localStorage.setItem("cityId", "29253");
// };

export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(NavHeader)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])], 64);
    };
  }
};