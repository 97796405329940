import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/home"),
  },
  {
    path: "/map-overview",
    name: "MapOverview",
    component: () =>
      import(/* webpackChunkName: "MapOverview" */ "../views/mapOverview"),
  },
  {
    path: "/agricultural-land",
    name: "AgriculturalLand",
    component: () =>
      import(
        /* webpackChunkName: "AgriculturalLand" */ "../views/agriculturalLand"
      ),
  },
  {
    path: "/population-employment",
    name: "PopulationEmployment",
    component: () =>
      import(
        /* webpackChunkName: "PopulationEmployment" */ "../views/populationEmployment"
      ),
  },
  {
    path: "/party-policy",
    name: "PartyPolicy",
    component: () =>
      import(/* webpackChunkName: "Partypolicy" */ "../views/partyPolicy"),
  },
  {
    path: "/people-livelihood",
    name: "PeopleLivelihood",
    component: () =>
      import(
        /* webpackChunkName: "PeopleLivelihood" */ "../views/peopleLivelihood"
      ),
  },
  {
    path: "/ecology",
    name: "ecology",
    component: () =>
      import(/* webpackChunkName: "ecology" */ "../views/ecology"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
