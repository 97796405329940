export default {
  production: {
    api: "https://village.hemeixiangcun.xn--fiqs8s",
    // api: "http://192.168.2.9:8700"
  },
  development: {
    api: "https://village.hemeixiangcun.xn--fiqs8s",
    // api: "http://192.168.2.9:8700"
  },
};
