import operate from "./operate";
const env = process.env.NODE_ENV || "production";
const apiConfig = operate[env];
import axios from "axios";
// import { ElMessageBox } from "element-plus";

const instance = axios.create({
  baseURL: apiConfig.api,
  timeout: 3000,
  headers: { "content-type": "application/json;charset:utf-8" },
});
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (isError(response)) {
      catchError(response);
      Promise.reject(response);
    }
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    error.response && catchError(error.response);
    return Promise.reject(error);
  }
);
const isError = (res) => {
  if (res.status && res.status !== 200) return true;
  if (res.data.code && res.data.code !== 200) return true;
  return false;
};
const catchError = () => {
  // res
  // 判断 请求api 格式是否正确
  // if (res.errMsg === "request:fail" || res.message === "Network Error") {
  //   // ElMessageBox.alert("服务异常", "温馨提示");
  //   return;
  // } else if (res.data.msg) {
  //   // ElMessageBox.alert(res.data.msg, "温馨提示");
  // }
};

export default instance;
